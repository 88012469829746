import React from 'react';

import BannersCarouselSlot from '../BannersCarouselSlot/BannersCarouselSlot';
import DeliveryInfoBarMobile from '../DeliveryInfoBarMobile/DeliveryInfoBarMobile';
import { HOMEPAGE_SLOT_DATA_TEST_ID } from '../HomepageSlots/HomepageSlots';
import NavigationSlot from '../NavigationSlot/NavigationSlot';

import PromotionsCarouselSlot from '../PromotionsCarouselSlot/PromotionsCarouselSlot';
import SnowfallToggleBarMobile from '../SnowfallToggleBarMobile/SnowfallToggleBarMobile';

import type {
  IHomepageBannersCarouselSlot,
  IHomepageNavigationSlot,
  IHomepagePromotionsCarouselSlot,
  IHomepageSlot
} from 'types';

import {
  HOMEPAGE_SLOT_TYPES,
} from 'types';

import styles from './HomepageIntroMobileSlots.module.scss';

const {
  BANNERS_CAROUSEL,
  PROMOTIONS_CAROUSEL,
  NAVIGATION
} = HOMEPAGE_SLOT_TYPES;

interface Props {
  loading?: boolean,
  slots: Record<string, IHomepageSlot>
}

const HomepageIntroMobileSlots = (props: Props) => {

  const {
    slots = {},
    loading,
  } = props;

  // Render

  return (
    <>

      {/* Banners Carousel Slot */}
      <BannersCarouselSlot
        className={styles.bannersCarouselSlot}
        sliderClassName={styles.slider}
        slideClassName={styles.slide}
        loading={loading}
        {...(slots[BANNERS_CAROUSEL] as IHomepageBannersCarouselSlot)}
        dataTestId={HOMEPAGE_SLOT_DATA_TEST_ID}
      />

      {/* Delivery Info Bar */}
      <DeliveryInfoBarMobile />

      {/* Snowfall Toggle Bar */}
      <SnowfallToggleBarMobile />

      {/* Navigation Slot */}
      <NavigationSlot
        className={styles.navigationSlot}
        {...(slots[NAVIGATION] as IHomepageNavigationSlot)}
        dataTestId={HOMEPAGE_SLOT_DATA_TEST_ID}
      />

      {/* Promotions Carousel Slot */}
      <PromotionsCarouselSlot
        className={styles.promotionsCarouselSlot}
        loading={loading}
        {...(slots[PROMOTIONS_CAROUSEL] as IHomepagePromotionsCarouselSlot)}
        dataTestId={HOMEPAGE_SLOT_DATA_TEST_ID}
      />

    </>
  );
};

export default HomepageIntroMobileSlots;
