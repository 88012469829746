import React from 'react';
import classnames from 'classnames';

import { PromotionsCarousel } from 'components/promotions';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useInView } from 'hooks/common/useInView';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBreakpoint } from 'hooks/useBreakpoint';

import type { Props as PromotionCardProps } from 'components/promotions/PromotionCard/PromotionCard';

import type { IHomepagePromotionsCarouselItem } from 'types';

import styles from './PromotionsCarouselSlot.module.scss';

interface Props {
  className?: string,
  //
  index: number,
  loading?: boolean,
  promotions: IHomepagePromotionsCarouselItem[],
  //
  cardProps?: PromotionCardOverrideProps,
  //
  dataTestId: string
}

interface PromotionCardOverrideProps extends Partial<PromotionCardProps>{}

const PromotionsCarouselSlot = (props: Props) => {

  const {
    className,
    //
    index: slotIndex,
    loading,
    promotions,
    //
    cardProps = {},
    //
    dataTestId,
  } = props;

  // Hooks

  const analytics = useAnalytics();
  const isLg = useBreakpoint('lg', 'up');

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      analytics.viewPromotionsCarousel(slotIndex);
      unobserve();
    }
  });

  // Empty

  if (isArrayEmpty(promotions)) {
    return null;
  }

  // Render

  return (
    <div
      ref={observe}
      className={classnames(styles.root, className)}
      data-testid={dataTestId}
    >

      {/* Carousel */}
      <PromotionsCarousel
        //
        className={classnames(styles.carousel, styles.promotionsCarousel)}
        sliderClassName={styles.slider}
        cardClassName={styles.card}
        //
        loading={loading}
        promotions={promotions}
        //
        withNavigation={isLg}
        withBackground={isLg}
        withDescription={isLg}
        fill={isLg}
        cardProps={{
          //
          titleClassName: styles.cardTitle,
          //
          fill: isLg,
          inline: isLg,
          //
          withTitle: true,
          withDates: true,
          withArrow: true,
          asThumbnail: !isLg,
          //
          ...cardProps
        }}
        //
        data-testid="promotions-carousel"
      />

    </div>
  );
};

// Export

export default PromotionsCarouselSlot;
