import React from 'react';
import classnames from 'classnames';

import { ProductsCarousel } from 'components/product';
import { HOMEPAGE_CAROUSEL_BREAKPOINTS } from 'helpers/HomepageHelpers';

import type {
  IHomepageProductsCarouselLink,
  IProduct
} from 'types';

import styles from './ProductsCarouselSlot.module.scss';

interface Props {
  className?: string,
  //
  index: number,
  analyticsListId: string,
  analyticsListName: string,
  //
  title: string,
  link: IHomepageProductsCarouselLink,
  // TODO: move to types
  image: {
    src: string,
    url: string
  },
  products: IProduct[],
  //
  dataTestId: string
}

const ProductsCarouselSlot = (props: Props) => {

  const {
    className,
    //
    index,
    analyticsListId,
    analyticsListName,
    //
    title,
    link,
    image,
    products,
    //
    dataTestId
  } = props;

  // Render

  return (
    <div
      className={classnames(styles.root, className)}
      data-testid={dataTestId}
    >
      <ProductsCarousel
        index={index}
        //
        title={title}
        link={link}
        image={image}
        products={products}
        //
        breakpoints={HOMEPAGE_CAROUSEL_BREAKPOINTS}
        //
        productCardProps={{
          bypassQuantityVariants: true
        }}
        productListProps={{
          analyticsListId,
          analyticsListName
        }}
      />
    </div>
  );
};

// Export

export default ProductsCarouselSlot;
