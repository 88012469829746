import React from 'react';
import classnames from 'classnames';

import { SnowfallToggle } from 'components/seasonal';
import { isSnowfallEnabled } from 'helpers/SeasonalHelpers';

import styles from './SnowfallToggleBarMobile.module.scss';

interface Props {
  className?: string
}

const SnowfallToggleBarMobile = (props: Props) => {

  const { className } = props;

  if (!isSnowfallEnabled()) return null;

  return (
    <SnowfallToggle
      className={classnames(styles.root, className)}
      textClassName={styles.text}
      switchClassName={styles.switch}
      withText
      clickable
    />
  );
};

export default SnowfallToggleBarMobile;
