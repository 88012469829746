import React from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import classnames from 'classnames';

import { Button, Icon } from 'components/ui';
import { useSearchDispatchHelpers, useSearchStore } from 'context/SearchContext';
import { useTranslation } from 'hooks/common/useTranslation';
import { useAnalytics } from 'hooks/useAnalytics';
import { useIsClient } from 'hooks/useIsClient';

import HomepageIntroMobileSlots from './HomepageIntroMobileSlots';

import type { IHomepageSlot } from 'types';

import styles from './HomepageIntroMobile.module.scss';

interface Props {
  className?: string,
  //
  loading?: boolean,
  slots: Record<string, IHomepageSlot>
}

const HomepageIntroMobile = (props: Props) => {

  const {
    className,
    slots = {},
    loading,
  } = props;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Inline search bar */}
      <SearchBar />

      {/* Slots */}
      <HomepageIntroMobileSlots
        slots={slots}
        loading={loading}
      />

    </div>
  );
};

// Components

// --- Search Bar

const SearchBar = () => {

  // Refs

  const searchBarRef = React.useRef<HTMLDivElement>(null);

  // Hooks

  const { t } = useTranslation();
  const analytics = useAnalytics();

  const isClient = useIsClient();

  const { isHeaderSearchBarActive } = useSearchStore();

  const {
    toggleHeaderSearchBar,
    setInlineSearchBarVisibility
  } = useSearchDispatchHelpers();

  const { isIntersecting } = useIntersectionObserver(searchBarRef, {
    threshold: [1]
  });

  // Handlers

  const onClick = () => {
    analytics.openSearch();
    toggleHeaderSearchBar(true);
  };

  // Effects

  React.useEffect(() => {
    setInlineSearchBarVisibility(isIntersecting);
  }, [isIntersecting]);

  // Classes

  const classes = classnames(
    styles.searchFieldWrapper,
    { [styles.rendered]: isClient },
    { [styles.hidden]: isHeaderSearchBarActive }
  );

  return (
    <div
      ref={searchBarRef}
      className={classes}
    >
      <div className={styles.searchField}>
        <Button
          tag="div"
          rounded={false}
          className={styles.searchFieldBtn}
          contentClassName={styles.searchFieldBtnContent}
          variant="subtle"
          endIcon={<Icon name="search" size={20} />}
          onClick={onClick}
        >
          {t('SEARCH.PLACEHOLDER')}
        </Button>
      </div>
    </div>
  );
};

// Export

export default HomepageIntroMobile;
