import React from 'react';
import classnames from 'classnames';

import {
  Banner, Carousel
} from 'components/ui';
import { CONFIG } from 'constants/config';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useAnalytics } from 'hooks/useAnalytics';
import { useBreakpoint } from 'hooks/useBreakpoint';

import type { CarouselApi } from 'components/ui/Carousel';
import type { IBannerImage, IHomepageBanner, IImage } from 'types';

import styles from './BannersCarouselSlot.module.scss';

// Types

interface Props {
  className?: string,
  sliderClassName?: string,
  slideClassName?: string,
  //
  banners: IHomepageBanner[],
  loading: boolean,
  //
  dataTestId: string
}

interface IBannerClick {
  e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
  index: number,
  name: string,
  isActive: boolean,
  isPrev: boolean,
  isNext: boolean
}

// Constants

const AUTOPLAY_DELAY = 1000;

// Render

const BannersCarouselSlot = (props: Props) => {

  const {
    className,
    sliderClassName,
    slideClassName,
    //
    banners,
    loading,
    //
    dataTestId
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const [carouselApi, setCarouselApi] = React.useState<CarouselApi>();
  const [navigation, setNavigation] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [isPaused, setIsPaused] = React.useState(false);

  const isSm: boolean = useBreakpoint('sm', 'down');
  const isUpToLg = useBreakpoint('lg', 'down');

  // Variables

  const totalSlides = banners?.length;
  const previousIndex = (selectedIndex - 1 + totalSlides) % totalSlides;
  const nextIndex = (selectedIndex + 1) % totalSlides;

  // This is used for the nav buttons
  const autoplayTimeoutRef = React.useRef(null);

  const autoplayDuration: number = isUpToLg
    ? CONFIG.DURATIONS.BANNER_CAROUSEL_AUTOPLAY_RESPONSIVE
    : CONFIG.DURATIONS.BANNER_CAROUSEL_AUTOPLAY;

  // Parallax

  const TWEEN_FACTOR_BASE = 0.2;

  const tweenFactor = React.useRef(0);
  const tweenNodes = React.useRef<HTMLElement[]>([]);

  const setTweenNodes = React.useCallback((emblaApi: CarouselApi): void => {
    tweenNodes.current = emblaApi.slideNodes().map((slideNode) => {
      return slideNode.querySelector('[class*="parallaxLayer"]') as HTMLElement;
    });
  }, []);

  const setTweenFactor = React.useCallback((emblaApi: CarouselApi) => {
    tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
  }, []);

  const tweenParallax = React.useCallback(
    (emblaApi: CarouselApi, eventName?: any) => {
      const engine = emblaApi.internalEngine();
      const scrollProgress = emblaApi.scrollProgress();
      const slidesInView = emblaApi.slidesInView();
      const isScrollEvent = eventName === 'scroll';

      emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
        let diffToTarget = scrollSnap - scrollProgress;
        const slidesInSnap = engine.slideRegistry[snapIndex];

        slidesInSnap.forEach((slideIndex) => {
          if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

          if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach((loopItem) => {
              const target = loopItem.target();

              if (slideIndex === loopItem.index && target !== 0) {
                const sign = Math.sign(target);

                if (sign === -1) {
                  diffToTarget = scrollSnap - (1 + scrollProgress);
                }
                if (sign === 1) {
                  diffToTarget = scrollSnap + (1 - scrollProgress);
                }
              }
            });
          }

          const translate = diffToTarget * (-1 * tweenFactor.current) * 100;
          const tweenNode = tweenNodes.current[slideIndex];
          if (tweenNode) {
            tweenNode.style.transform = `translateX(${translate}%)`;
          }
        });
      });
    },
    []
  );

  // Effects

  React.useEffect(() => {
    setNavigation(!isUpToLg);
  }, [isUpToLg]);

  React.useEffect(() => {
    if (!carouselApi) return;

    setTweenNodes(carouselApi);
    setTweenFactor(carouselApi);
    tweenParallax(carouselApi);

    carouselApi
      .on('reInit', setTweenNodes)
      .on('reInit', setTweenFactor)
      .on('reInit', tweenParallax)
      .on('scroll', tweenParallax)
      .on('slideFocus', tweenParallax);
  }, [carouselApi, tweenParallax]);

  // Clearing the setTimeout for the navButtons pause
  React.useEffect(() => {
    return () => clearTimeout(autoplayTimeoutRef.current);
  }, []);

  // Handlers

  const handleCarouselPause = () => {
    clearTimeout(autoplayTimeoutRef.current);
    autoplayTimeoutRef.current = setTimeout(() => {
      setIsPaused(true);
    }, AUTOPLAY_DELAY);
  };
  const handleCarouselResume = () => {
    clearTimeout(autoplayTimeoutRef.current);
    autoplayTimeoutRef.current = setTimeout(() => {
      setIsPaused(false);
    }, AUTOPLAY_DELAY);
  };
  const handleSelect = React.useCallback(() => {
    if (carouselApi) {
      setSelectedIndex(carouselApi.selectedScrollSnap());
    }
  }, [carouselApi]);

  const onInit = (api: CarouselApi) => {
    setCarouselApi(api);
    api.on('select', handleSelect);
  };

  const onBannerClick = (index: number, name: string) => {
    if (!name) return;
    analytics.selectCarouselBanner(index + 1, name);
  };

  const onBannerEnter = (index: number, name: string) => {
    if (!name) return;
    analytics.viewCarouselBanner(index + 1, name);
  };

  const handleCarouselInteraction = ({
    e,
    index,
    name,
    isActive,
    isPrev,
    isNext,
  }: IBannerClick) => {
    if (loading) {
      e.preventDefault();
      return;
    }

    if (isActive) {
      onBannerClick(index, name);
      return;
    }

    e.preventDefault();

    if (isPrev) {
      carouselApi?.scrollPrev();
    }

    if (isNext) {
      carouselApi?.scrollNext();
    }
  };

  // Empty

  if (isArrayEmpty(banners)) {
    return null;
  }

  // Render

  return (
    <div
      className={classnames(
        styles.root,
        { [styles.loading]: loading },
        className
      )}
      data-testid={dataTestId}
    >
      <Carousel
        className={classnames(
          styles.carousel,
        )}
        slidesWrapperClassName={classnames(
          styles.slider,
          sliderClassName
        )}
        nextClassName={classnames(
          styles.carouselNavBtn,
          styles.carouselNextBtn,
        )}
        prevClassName={classnames(
          styles.carouselNavBtn,
          styles.carouselPrevBtn,
        )}
        slideClassName={styles.slide}
        paginationClassName={classnames(
          styles.carouselPagination,
        )}
        //
        onInit={onInit}
        //
        autoplay={isPaused ? false : autoplayDuration}
        loop={!isSm}
        duration={35}
        //
        slidesPerView={1}
        spaceBetween={16}
        //
        navigation={navigation}
        onMouseEnter={handleCarouselPause}
        onMouseLeave={handleCarouselResume}
        //
        pagination
      >
        {
          banners?.map(({
            analyticsListId,
            analyticsListName,
            name,
            image,
            url,
            newTab,
          }, index) => {
            const isActive = selectedIndex === index;
            const isPrev = previousIndex === index;
            const isNext = nextIndex === index;

            return (
              <div className={styles.parallaxContainer} key={index}>
                <div className={styles.parallaxLayer}>
                  <Banner
                    className={classnames(
                      styles.banner,
                      { [styles.active]: isActive },
                      { [styles.prev]: isPrev },
                      { [styles.next]: isNext },
                      { [styles.isUpToLg]: isUpToLg },
                    )}
                    //
                    image={getBannerSrc(image)}
                    imageProps={{ priority: index === 0 ? true : undefined }}
                    desktopProps={{
                      width: 1435,
                      height: 557,
                    }}
                    responsiveProps={{
                      width: 828,
                      height: 424,
                    }}
                    hoverable={isActive}
                    rounded
                    loading={loading}
                    //
                    href={url}
                    newTab={newTab}
                    //
                    listProps={{
                      analyticsListId,
                      analyticsListName
                    }}
                    //
                    onClick={(e) => handleCarouselInteraction({
                      e, index, name, isActive, isPrev, isNext,
                    })}
                    onEnter={() => {
                      onBannerEnter(index, name);
                    }}
                  />
                </div>
              </div>
            );
          })
        }
      </Carousel>
    </div>
  );
};

// Helpers

const getBannerSrc = (image: IBannerImage): {
  desktop: IImage,
  responsive: IImage
} => {
  const {
    default: regular,
    webp,
    responsiveWebp,
    responsive
  } = image;

  return {
    desktop: {
      default: '',
      jpg: regular,
      webp
    },
    responsive: {
      default: '',
      jpg: responsive,
      webp: responsiveWebp
    }
  };
};

// Export

export default BannersCarouselSlot;
