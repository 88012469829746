import React from 'react';

import { RecipeCarouselSlot } from 'components/recipe';
import { HOMEPAGE_RECIPE_CAROUSEL_BREAKPOINTS } from 'constants/recipes';

import type { IHomepageRecipesPromoCarouselLink, IRecipeCardData } from 'types';
// import styles from './RecipesPromoCarouselSlot.module.scss';

interface Props {
  className?: string,
  //
  index: number,
  analyticsListId: string,
  analyticsListName: string,
  //
  recipes: IRecipeCardData[],
  title: string,
  link?: IHomepageRecipesPromoCarouselLink,
  //
  dataTestId?: string
}

const RecipesPromoCarouselSlot = (props: Props) => {

  const {
    className,
    //
    index,
    analyticsListId,
    analyticsListName,
    //
    recipes,
    title,
    dataTestId,
    link
  } = props;

  return (
    <RecipeCarouselSlot
      className={className}
      //
      index={index}
      analyticsListId={analyticsListId}
      analyticsListName={analyticsListName}
      //
      recipes={recipes}
      title={title}
      breakpoints={HOMEPAGE_RECIPE_CAROUSEL_BREAKPOINTS}
      link={link}
      dataTestId={dataTestId}
    />
  );
};

export default RecipesPromoCarouselSlot;
