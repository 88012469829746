import React from 'react';
import classnames from 'classnames';

import { Banner } from 'components/ui';
import { useAnalytics } from 'hooks/useAnalytics';

import type { IHomepageBanner } from 'types';

import styles from './HalfBannerSlot.module.scss';

interface Props {
  className?: string,
  bannerClassName?: string,
  //
  index?: number,
  halfBanners: IHomepageBanner[],
  //
  dataTestId: string
}

const HalfBannerSlot = (props: Props) => {

  const {
    className,
    bannerClassName,
    //
    index: slotIndex,
    //
    halfBanners,
    //
    dataTestId
  } = props;

  // Hooks

  const analytics = useAnalytics();

  // Handlers

  const onBannerClick = (index: number, name: string) => {
    if (!name) return;
    analytics.selectHalfBanner(index + 1, name, slotIndex);
  };

  const onBannerEnter = (index: number, name: string) => {
    if (!name) return;
    analytics.viewHalfBanner(index + 1, name, slotIndex);
  };

  // Render

  return (
    <div className={classnames(styles.root, className)} data-testid={dataTestId}>
      {
        halfBanners?.map(({
          analyticsListId,
          analyticsListName,
          name,
          image,
          url,
          newTab,
        }, index) => (
          <div className={styles.halfBannerWrapper} key={index}>
            <Banner
              className={classnames(styles.halfBanner, bannerClassName)}
              //
              href={url}
              newTab={newTab}
              //
              image={image}
              hoverable
              imageProps={{
                alt: name,
                width: 900,
                height: 335
              }}
              rounded
              //
              listProps={{
                analyticsListId,
                analyticsListName
              }}
              //
              onClick={() => onBannerClick(index, name)}
              onEnter={() => onBannerEnter(index, name)}
            />
          </div>
        ))
      }
    </div>
  );
};

// Export

export default HalfBannerSlot;
