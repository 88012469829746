export const HOMEPAGE_CAROUSEL_BREAKPOINTS = {
  1700: {
    slidesPerView: 7
  },
  1400: {
    slidesPerView: 6
  },
  1160: {
    slidesPerView: 5
  },
  900: {
    slidesPerView: 4
  },
  640: {
    slidesPerView: 3
  }
};
