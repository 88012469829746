import React from 'react';
import { useTranslation } from 'next-i18next';

import { ProductsCarousel } from 'components/product';
import { FEATURES } from 'constants/features';
import { APP_ROUTES } from 'constants/routes';
import { HOMEPAGE_CAROUSEL_BREAKPOINTS } from 'helpers/HomepageHelpers';
import { useUser } from 'hooks/data/useUser';
import { useUsualProducts } from 'hooks/data/useUsualProducts';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import styles from './UsualProductsSlot.module.scss';

interface Props {
  index?: number
}

const UsualProductsSlot = (props: Props) => {

  const {
    index
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Feature Flags

  const {
    isEnabled: isFeatureEnabled
  } = useFeatureFlag(FEATURES.HP_FRESHLIST_WIDGET);

  // Data Hooks

  const { data: user } = useUser({ isOptional: true });

  const {
    data, isLoading, isError, isSuccess
  } = useUsualProducts({
    page: 1,
    itemsPerPage: 11
  }, {
    enabled: !!user && isFeatureEnabled
  });

  // Empty

  if (
    !user
    || isError
    || (isSuccess && data?.items?.length < 6)
    || !isFeatureEnabled
  ) return null;

  // Props

  const {
    analyticsListId,
    analyticsListName,
    items = []
  } = data || {};

  const title = t('NAVIGATION.USUAL_PRODUCTS');

  // Render

  return (
    <div className={styles.root}>
      <ProductsCarousel
        index={index}
        //
        title={title}
        link={{ url: APP_ROUTES.MY_FRESHLIST, title: t('CAROUSEL.ALL') }}
        products={items}
        //
        loading={isLoading}
        breakpoints={HOMEPAGE_CAROUSEL_BREAKPOINTS}
        //
        productListProps={{
          analyticsListId,
          analyticsListName
        }}
      />
    </div>
  );
};

// Export

export default UsualProductsSlot;
