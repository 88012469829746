import React from 'react';

import { FEATURES } from 'constants/features';
import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { useFeatureFlag } from 'hooks/useFeatureFlag';

import BannersCarouselSlot from '../BannersCarouselSlot/BannersCarouselSlot';
import HalfBannerSlot from '../HalfBannerSlot/HalfBannerSlot';
import NavigationSlot from '../NavigationSlot/NavigationSlot';
import ProductsCarouselSlot from '../ProductsCarouselSlot/ProductsCarouselSlot';
import RecipesPromoCarouselSlot from '../RecipesPromoCarouselSlot/RecipesPromoCarouselSlot';
import UsualProductsSlot from '../UsualProductsSlot/UsualProductsSlot';

import type {
  HomepageSlotType,
  IHomepageSlot
} from 'types';

import {
  HOMEPAGE_SLOT_TYPES,
} from 'types';

import styles from './HomepageSlots.module.scss';

interface Props {
  slots: IHomepageSlot[]
}

const HomepageSlots = (props: Props) => {

  const { slots = [] } = props;

  // Hooks

  const {
    isEnabled: shouldDisableManuallyRetrieveUsuals
  } = useFeatureFlag(FEATURES.DISABLE_MANUALLY_RETRIEVE_USUALS);

  // Empty

  if (isArrayEmpty(slots)) {
    return null;
  }

  // Render

  return (
    <div
      className={styles.root}
      data-testid="homepage-slots"
    >

      {/* Slots */}
      {
        slots?.map((slot, index) => {
          if (!slot) return null;

          const {
            type,
            index: slotIndex,
            ...rest
          } = slot;

          return (
            <div key={`${type}-${index}`}>

              {/* Slot */}
              <Slot
                index={slotIndex}
                type={type}
                {...rest}
              />

              {/* Usual Products Carousel */}
              {
                (index === 1 && !shouldDisableManuallyRetrieveUsuals)
                  ? (
                    <UsualProductsSlot
                      index={slotIndex}
                    />
                  ) : null
              }

            </div>
          );
        })
      }
    </div>
  );
};

// --- Slot

interface SlotProps {
  index: number,
  type: HomepageSlotType
}

const Slot = (props: SlotProps) => {
  const {
    index,
    type,
    ...rest
  } = props;

  const Component = SLOT_COMPONENTS[type];

  if (!Component) return null;

  return (
    <Component
      {...rest}
      index={index}
      dataTestId={`${HOMEPAGE_SLOT_DATA_TEST_ID}-${type}`}
    />
  );
};

// Constants

export const SLOT_COMPONENTS = {
  [HOMEPAGE_SLOT_TYPES.BANNERS_CAROUSEL]: BannersCarouselSlot,
  [HOMEPAGE_SLOT_TYPES.HALF_BANNER]: HalfBannerSlot,
  [HOMEPAGE_SLOT_TYPES.NAVIGATION]: NavigationSlot,
  [HOMEPAGE_SLOT_TYPES.PRODUCTS_CAROUSEL]: ProductsCarouselSlot,
  [HOMEPAGE_SLOT_TYPES.RECIPE_PROMOTIONS_CAROUSEL]: RecipesPromoCarouselSlot,
};

export const HOMEPAGE_SLOT_DATA_TEST_ID = 'homepage-slot';

// Export

export default HomepageSlots;
