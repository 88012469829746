/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import classnames from 'classnames';

import { Image, Link } from 'components/ui';
import { CONFIG } from 'constants/config';
import { useInView } from 'hooks/common/useInView';
import { useTranslation } from 'hooks/common/useTranslation';
import { useAnalytics } from 'hooks/useAnalytics';

import type { IHomepageNavigationButton } from 'types';

import styles from './NavigationSlot.module.scss';

interface Props {
  className?: string,
  //
  index: number,
  buttons: IHomepageNavigationButton[],
  //
  dataTestId: string
}

const NavigationSlot = (props: Props) => {

  const {
    className,
    //
    index: slotIndex,
    buttons,
    //
    dataTestId
  } = props;

  // Hooks

  const analytics = useAnalytics();

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      analytics.viewNavigationButtons(slotIndex);
      unobserve();
    }
  });

  // Render

  return (
    <div
      ref={observe}
      className={classnames(styles.root, className)}
      data-testid={dataTestId}
    >
      {
        buttons?.map(({
          icon, title, url, count
        }, index) => (
          <NavigationItem
            key={index}
            icon={icon}
            title={title}
            url={url}
            count={count}
            onEnter={() => {
              analytics.viewNavigationButton(title, slotIndex, index);
            }}
            onClick={() => {
              analytics.selectNavigationButton(title, slotIndex, index);
            }}
          />
        ))
      }
    </div>
  );
};

// Components

interface NavigationItemProps {
  icon: string,
  title: string,
  url: string,
  count: number,
  onClick: () => void,
  onEnter: () => void
}

// TODO: remove after BE implementation

const NEW_ROUTES = [
  '/retete',
];

const NavigationItem = (props: NavigationItemProps) => {
  const {
    icon,
    title,
    url,
    count,
    onClick,
    onEnter,
  } = props;

  const { t } = useTranslation();

  const { observe } = useInView({
    threshold: 0.75,
    onEnter: ({ unobserve }) => {
      onEnter();
      unobserve();
    }
  });

  const isNewRoute = NEW_ROUTES.includes(url);

  return (
    <div
      ref={onEnter ? observe : null}
      className={styles.item}
    >
      <Link
        href={url}
        passHref
        prefetch={CONFIG.PREFETCH_LINKS}
      >
        <a
          className={styles.link}
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
        >

          {/* Label */}
          {
            isNewRoute && (
              <div className={styles.label}>
                {t('LABELS.NEW')}
              </div>
            )
          }

          {/* Image */}
          {
            icon && (
              <div className={styles.imageContainer}>
                <Image
                  className={styles.image}
                  width={80}
                  height={80}
                  src={icon}
                  quality={100}
                  optimize
                  alt={title}
                />
              </div>
            )
          }

          {/* Title */}
          <div className={styles.text}>
            {title}
          </div>

          {/* Count */}
          {
            count
              ? <div className={styles.count}><span>{count}</span></div>
              : null
          }

        </a>
      </Link>
    </div>
  );
};

// Export

export default NavigationSlot;
