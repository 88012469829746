import { useQuery } from '@tanstack/react-query';

import { QUERY_KEYS } from 'constants/query-keys';
import { createQueryKey } from 'helpers/ReactQueryHelpers';
import { getUsualProducts } from 'services/ProductsService';

import type {
  IListingRequestParams,
  IProductsListing,
  ReactQueryOptions
} from 'types';

export function useUsualProducts(
  params: IListingRequestParams,
  queryOptions: ReactQueryOptions<IProductsListing> = {}
) {

  return useQuery({
    queryKey: getKey(params),
    queryFn: () => getUsualProducts(params),
    ...queryOptions,
  });
}

export function getKey(params: IListingRequestParams) {
  const { page, itemsPerPage, searchQuery } = params;
  return createQueryKey([
    QUERY_KEYS.USUAL_PRODUCTS,
    page,
    itemsPerPage,
    searchQuery
  ]);
}
