import React from 'react';
import { useTranslation } from 'next-i18next';
import classnames from 'classnames';

import { AddressDeliveryIntervals, AddressSearch } from 'components/address';
import { Icon, Image } from 'components/ui';
import { HOLIDAY_ICONS } from 'constants/seasonal';
import { useDeliveryInfoDispatchHelpers } from 'context/DeliveryInfoContext';
import { getActiveHoliday, isChristmasSeason, isEasterSeason } from 'helpers/SeasonalHelpers';
import { useHeaderDeliveryAddress } from 'hooks/useHeaderDeliveryAddress';
import { useHeaderDeliveryInterval } from 'hooks/useHeaderDeliveryInterval';

import { Holiday } from 'types/holidays';

import styles from './DeliveryInfoBarMobile.module.scss';

interface Props {
  className?: string
}

const DeliveryInfoBarMobile = (props: Props) => {

  const {
    className
  } = props;

  // Hooks

  const { t } = useTranslation();

  // Data Hooks

  const {
    label: deliveryAddressLabel,
    token: deliveryAddressToken,
    isAuthenticated,
    isPlaceholder
  } = useHeaderDeliveryAddress({ isMobile: true });

  const {
    label: deliveryIntervalLabel
  } = useHeaderDeliveryInterval(deliveryAddressToken, !!isAuthenticated);

  // Context Hooks

  const { showDeliveryInfoModal } = useDeliveryInfoDispatchHelpers();

  // Handlers

  const onClick = () => {
    if (isPlaceholder) {
      showDeliveryInfoModal(<AddressSearch />);
    } else {
      showDeliveryInfoModal(<AddressDeliveryIntervals />);
    }
  };

  // Render

  return (
    <div className={classnames(styles.root, className)} onClick={onClick}>

      {/* Icon */}
      <div className={styles.iconWrapper}>
        <div className={classnames(styles.iconContainer, { [styles.holiday]: (isChristmasSeason() || isEasterSeason()) })}>
          <DeliveryInfoBarMobileIcon />
        </div>
      </div>

      {/* Content */}
      <div className={styles.content}>
        <div className={styles.info}>{t('HOMEPAGE.DELIVERY_BAR.INFO')}</div>
        {
          isPlaceholder ? (
            <>
              {/* Placeholder Title - address */}
              <div className={styles.title}>
                {deliveryAddressLabel}
              </div>
            </>
          ) : (
            <>
              {/* Title - interval */}
              <div className={styles.title}>
                {deliveryIntervalLabel}
              </div>

              {/* Subtitle - address */}
              <div className={styles.subtitle}>
                <span className={styles.address}>
                  {deliveryAddressLabel}
                </span>
              </div>
            </>
          )
        }
      </div>

      {/* Chevron */}
      <div className={styles.chevronContainer}>
        <Icon
          size={24}
          strokeWidth={1.5}
          name="chevron-right"
          className={styles.chevronIcon}
        />
      </div>
    </div>
  );
};

const DeliveryInfoBarMobileIcon = () => {
  const holiday = getActiveHoliday();

  if (holiday) {
    const imageSrc = getHolidayImage(holiday);

    if (imageSrc) {
      return (
        <Image
          className={styles.image}
          height={39}
          width={38}
          src={imageSrc}
        />
      );
    }
  }
  return (
    <Icon
      className={styles.truckIcon}
      name="truck"
      size={22}
    />
  );
};

function getHolidayImage(holiday: Holiday) {
  const holidayImages = {
    [Holiday.Easter]: HOLIDAY_ICONS.DELIVERY_INFO_BAR_MOBILE.EASTER,
    [Holiday.Christmas]: HOLIDAY_ICONS.DELIVERY_INFO_BAR_MOBILE.CHRISTMAS,
  };

  return holidayImages[holiday] || null;
}

export default DeliveryInfoBarMobile;
