import React from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-cool-inview';
import { debounce } from 'throttle-debounce';
import classnames from 'classnames';

import { MegaMenu, MegaMenuContent } from 'components/layout';
import { useLayoutDispatch } from 'context/LayoutContext';
import { isElementInViewport } from 'helpers/ScrollHelpers';
import { useIsMounted } from 'hooks/useIsMounted';
import { useResize } from 'hooks/useResize';

import type {
  IHomepageSlot,
  IMenuItemLink
} from 'types';

import styles from './HomepageIntroDesktop.module.scss';

const HomepageIntroDesktopSlots = dynamic(() => import('./HomepageIntroDesktopSlots'), { ssr: false });

interface Props {
  className?: string,
  loading?: boolean,
  slots: Record<string, IHomepageSlot>
}

interface IMenuController {
  element: HTMLDivElement,
  clearSelectedItem: () => void,
  resetScroll: () => void
}

const DEFAULT_INTRO_HEIGHT = 630;

const HomepageIntroDesktop = (props: Props) => {

  const {
    className,
    loading,
    slots = {}
  } = props;

  // Refs

  const isInsideRef = React.useRef<boolean>(false);

  const menuRef: React.MutableRefObject<HTMLDivElement | null> = React.useRef(null);
  const menuControllerRef = React.useRef<IMenuController>(null);

  const isMountedFn = useIsMounted();
  const isMounted = isMountedFn();

  const { ref: resizeRef, height: menuContentHeight } = useResize({ handleWidth: false });

  // Hooks

  const [selectedItem, setSelectedItem] = React.useState<IMenuItemLink>();

  // Intersection Observer Hook

  const {
    setHomepageMenuVisibility,
    setHomepageSubMenuVisibility
  } = useLayoutDispatch();

  const { observe } = useInView({
    threshold: [0.25],
    onEnter: () => {
      setHomepageMenuVisibility(true);
    },
    onLeave: () => {
      menuControllerRef?.current?.clearSelectedItem();
      setHomepageMenuVisibility(false);
    }
  });

  // Effects

  React.useEffect(() => {
    if (!isMounted) return;
    const isHomepageMenuVisible = isElementInViewport(menuRef.current, window, true);
    setHomepageMenuVisibility(isHomepageMenuVisible);
    return () => {
      setHomepageMenuVisibility(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  React.useEffect(() => {
    setHomepageSubMenuVisibility(!!selectedItem?.children?.length);
  }, [selectedItem?.children]);

  // Handlers

  const closeMenu = () => {
    if (!isInsideRef.current) {
      menuControllerRef?.current?.clearSelectedItem();
    }
  };

  const debouncedCloseMenu = debounce(100, closeMenu);

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Side */}
      <div className={styles.side}>

        {/* Mega Menu */}
        <MegaMenu
          ref={menuControllerRef}
          menuRef={(el: HTMLDivElement) => {
            resizeRef.current = el;
            menuRef.current = el;
            observe(el);
          }}
          //
          className={classnames(
            styles.menu,
            { [styles.expanded]: !!selectedItem?.children?.length }
          )}
          scrollbarClassName={styles.menuScrollbar}
          //
          onSelectedItemChange={setSelectedItem}
          onMouseEnter={() => {
            isInsideRef.current = true;
          }}
          onMouseLeave={() => {
            isInsideRef.current = false;
            debouncedCloseMenu();
          }}
        />
      </div>

      {/* Main */}
      <div
        className={styles.main}
        style={{
          height: menuContentHeight || DEFAULT_INTRO_HEIGHT
        }}
      >

        {/* Slots */}
        <HomepageIntroDesktopSlots
          className={styles.slots}
          slots={slots}
          loading={loading}
        />

        {/* Mega Menu Content */}
        <MegaMenuContent
          className={classnames(
            styles.menuContent,
            { [styles.expanded]: !!selectedItem?.children?.length }
          )}
          style={{
            height: menuContentHeight || DEFAULT_INTRO_HEIGHT
          }}
          selectedItem={selectedItem}
          onMouseEnter={() => {
            isInsideRef.current = true;
          }}
          onMouseLeave={() => {
            isInsideRef.current = false;
            debouncedCloseMenu();
          }}
        />

      </div>
    </div>
  );
};

// Export

export default HomepageIntroDesktop;
